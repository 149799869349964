import { Box } from 'components/Box';
import { Layout } from 'components/Layout';
import { Markdown } from 'components/Markdown';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const Custom = ({ data }) => (
    <Layout>
        <Box maxWidth="700px" m={16}>
            <Markdown>test content</Markdown>
        </Box>

        <div style={{ height: '50vh' }} />
    </Layout>
);

Custom.propTypes = {
    data: PropTypes.object.isRequired,
};

export default Custom;
