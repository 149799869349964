




import { RenderMarkdown, RenderMarkdownProps } from '../RenderMarkdown';
import { isExternalUrl } from '@audentio/utils/src/isExternalUrl';
import { Heading } from '../Heading';
import React from 'react';

// Components
// - renderer will try look for tag in this object
// - will fall back to html elements if not found
const components = {
    em: ({ children }) => <em>{children}</em>,
    img: ({ src }) => <img alt="" src={src} />,
    u: ({ children }) => <u>{children}</u>,
    strong: ({ children }) => <strong>{children}</strong>,
    // blockquote: ({ children }) => <Blockquote>{children}</Blockquote>,
    heading: ({ children, level }) => <Heading kind={`h${level}` as any}>{children}</Heading>,
    // code: props => {
    //     if (props.isBlock) {
    //         return <CodeBlock language={props.info}>{props.children}</CodeBlock>;
    //     }

    //     return <Code>{props.children}</Code>;
    // },
    p: ({ children }) => <p style={{ lineHeight: 1.5 }}>{children}</p>,
    // container: ({ type, children, info }) => {
    //     if (type === 'notice') {
    //         const kind = info.split('notice ')[1];
    //         return <Alert kind={kind}>{children}</Alert>;
    //     }

    //     return <div>{children}</div>;
    // },
    // temporarily append http to URLs without protocols
    // todo: @tusharsingh improve with regex(?)
    // a: ({ href, title, children }) => {
    //     let finalHref = !isExternalUrl(href) && href.indexOf('.') > 1 ? `http://${href}` : href;

    //     if (finalHref) {
    //         finalHref = finalHref.split('__CURRENT_URL__').join(window.location.href);
    //     }

    //     return (
    //         <Anchor external={href.indexOf('.') > 1} href={finalHref} title={title}>
    //             {children}
    //         </Anchor>
    //     );
    // },
    // ol: ({ children }) => <List ordered>{children}</List>,
    // ul: ({ children }) => <List>{children}</List>,
    // hr: () => <Hr />,
    // time: ({ children, format }) => {
    //     const props: TimestampProps = {
    //         children,
    //     };

    //     if (format === 'relative' || format === 'calendar') {
    //         props.output = format;
    //     } else {
    //         props.format = format;
    //     }

    //     return <Timestamp {...props} />;
    // },
};

export function Markdown(props: Omit<RenderMarkdownProps, 'components'>) {
    return <RenderMarkdown {...props} components={components} />;
}
